<template>
    <div>
        <div class="g-tile-row">
            <div class="g-tile">
                <div class="num">{{info.equipNum}}</div>
                <div class="text">调试完成设备</div>
            </div>
            <div class="g-tile">
                <div class="num">{{info.maxScore}}</div>
                <div class="text">调试最高分</div>
            </div>
            <div class="g-tile">
                <div class="num">{{info.avgScore}}</div>
                <div class="text">调试平均得分</div>
            </div>
        </div>
        
        <div class="my-flex-row">
            <div class="filter-botton" :class="{active: tabIndex == 0}" @click="tabIndex = 0">支腿臂架调试</div>
            <div class="filter-botton" :class="{active: tabIndex == 1}" @click="tabIndex = 1">系统调试</div>
            <div class="filter-botton" :class="{active: tabIndex == 2}" @click="tabIndex = 2">调试</div>
        </div>

        <div>
            <div v-for="(item,i) in filterTable.rank_list" :key="i" class="list-item-box">
                <div class="colorful-text-bar list-item-box__header color-style-blue">
                    <div class="text1">{{item[0]}}</div>
                    <div class="text2">{{item[1]}}</div>
                    <div class="grow-text small-gray-text">调试设备数：<span>{{item[2]}}</span></div>
                    <div class="text3">{{item[4]}}</div>
                </div>
                <div class="list-item-box__content small-gray-text">
                    <div>调试最高分：<span>{{item[3]}}</span></div>
                    <div>调试平均得分</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {getWorkerRankIndex, getWorkerRankStep} from '@/axios/device'
export default {
    data(){
        return {
            tabIndex: 0,
            table: [],
            info: {}
        }
    },
    computed: {
        ...mapGetters(['equipInfo']),
        filterTable(){
            return this.table[this.tabIndex] || {}
        }
    },
    watch: {
        tabIndex(){
            this.getIndexData()
        }
    },
    created(){
        this.getData()
        this.getIndexData()
    },
    methods: {
        getData(){
            let _date = new Date()
            let _params = {
                date: `${_date.getFullYear()}-${('0' + (_date.getMonth() + 1)).substr(-2)}`,
                equipType: this.equipInfo.equipType
            }
            getWorkerRankStep(_params).then(res => {
                this.table = res.content.step_rank
            })
        },
        getIndexData(){
            let _date = new Date()
            let _params = {
                date: `${_date.getFullYear()}-${('0' + (_date.getMonth() + 1)).substr(-2)}`,
                equipType: this.equipInfo.equipType
            }
            let _stepName = ""
            switch(this.tabIndex.toString()){
                case "0": _stepName = "支腿臂架调试"; break;
                case "1": _stepName = "系统调试"; break;
                case "2": _stepName = "调试"; break;
            }

            getWorkerRankIndex(Object.assign({}, _params, {stepName: _stepName})).then(res =>{
                this.info = res.content
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    .g-tile-row{
        margin: 20px 0;
    }
    .my-flex-row{
        display: flex;
        margin: 10px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        .filter-botton{
            font-size: 12px;
            padding: .3rem .5rem;
            margin: 0 .5rem;
        }
    }
    .list-item-box{
        margin: .416667rem .625rem;
        background: #f7f7f7;
        border-radius: 5px;
        padding: .416667rem .625rem;

        .list-item-box__header{
            display: flex;
            align-items: center;
            >.text2{
                flex-grow: 0;
            }
            >.text3{
                font-size: 20px;
            }
        }
        .grow-text{
            flex-grow: 1;
            text-align: left;
            margin-left: 5px;
        }
    }
    .list-item-box__content{
        display: flex;
        justify-content: space-between;
        margin: 5px 0 0 33px;
    }
</style>